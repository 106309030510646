<script setup>
import {ref, onMounted, nextTick, reactive} from 'vue';
import {gralHook} from "../../../../Hooks/gralHook";
import {homeSettingsService} from "../../../../services/HomeSettings.service";
import Quill from 'quill';
import "quill/dist/quill.snow.css";

const {getAlert, valFormularios, getFormData} = gralHook();

let quill = null;

const banner = ref([]);

const modificar = ref(false);

let modalImg = reactive({
    img: ''
});

const updateBanner = (ban) => {
    try {
        modificar.value = true;
        vModel.value = ban;
        quill.root.innerHTML = ban.title;

        console.log('vModel - ', vModel.value);
    } catch (error) {
        console.error('Error al actualizar el slider:', error);
    }
}

const vModel = ref({
    id: 0,
    title: '',
    description: '',
    title_button: 1,
    url_button: 0,
    image_full: '',
    img_mobile: ''
});

const openModalImg = async (img) => {
    modalImg.img = img;
    await nextTick();
}

const onUpdateBanner = async () => {

    let resp = '';

    resp = valFormularios('crudFormBanner');

    if (resp) {

        const _form = document.getElementById('crudFormBanner');

        const formData = new FormData(_form);

        // Agregar campos ocultos manualmente
        const hiddenFields = _form.querySelectorAll('input[type="hidden"]');
        hiddenFields.forEach(field => {
            formData.append(field.name, field.value);
        });

        // Agregar campos de archivo manualmente
        const fileFields = _form.querySelectorAll('input[type="file"]');
        fileFields.forEach(field => {
            formData.append(field.name, field.files[0]);
        });

        for (let key in vModel.value) {
            if (vModel.value.hasOwnProperty(key)) {
                formData.append(key, vModel.value[key]);
            }
        }

        formData.set('title', quill.root.innerHTML);

        resp = await homeSettingsService.updateBanner(formData, {isForm: true});

        getAlert(resp.process);

    }
};

const getBanner = async () => {
    banner.value = await homeSettingsService.getBanner();
    quill = new Quill("#editor_ban", {
        theme: "snow",
    });
}

onMounted(async () => {
    await getBanner();
})

</script>

<template>
    <section>

        <div class="col-md-12">

            <!-- begin::modal ADD BANNER-->
            <div id="add-banner" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
                 aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalCenterTitle">Añadir Imagen Deslizable</h5>
                            <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <!--  start card -->

                            <!-- begin::form-->
                            <form id="crudFormBanner" class="crudFormBanner">
                                <div class="card-body">
                                    <div class="form-row">
                                        <div class="form-group col-md-12">
                                            <label class="ul-form__label" for="">Título:</label>
                                            <div id="editor_ban" class="mb-4"></div>
                                            <!--- Aquí deberían poder hacer el salto de línea un Rich-Text-->
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-12">
                                            <label class="ul-form__label" for="">Texto Breve:</label>
                                            <input v-model="vModel.description" name="description" class="form-control"
                                                   id="description" type="text"
                                                   placeholder="Breve texto"/>
                                        </div>
                                    </div>

                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label class="ul-form__label" for="">Nombre del Botón:</label>
                                            <input v-model="vModel.title_button" name="title_button" class="form-control" id="" type="text" placeholder="Llamado a la acción"/>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="ul-form__label" for="">URL del Botón:</label>
                                            <input v-model="vModel.url_button" name="url_button" class="form-control" id="" type="text" placeholder="¿A dónde dirige el botón?"/>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group  col-md-12">
                                            <label class="ul-form__label" for="">
                                                Imagen Full HD para Computadoras (1110 px * 440 px):
                                            </label>
                                            <div v-if="vModel.image_full">
                                                <img :src="'/images/banners/'+vModel.image_full" class="img-fluid"/>
                                                <input type="hidden" name="current_img_full" :value="vModel.image_full">
                                            </div>
                                            <div class="input-group mb-12">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" id="inputGroupFileAddon01">Cargar</span>
                                                </div>
                                                <div class="custom-file">
                                                    <input name="image_full" class="custom-file-input" id="inputGroupFile01" type="file" aria-describedby="inputGroupFileAddon01"/>
                                                    <label class="custom-file-label" for="inputGroupFile01">
                                                        Elegir Imagen *.JPEG
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group  col-md-12">
                                            <label class="ul-form__label" for="">
                                                Imagen para Celulares (510 px * 395 px):
                                            </label>
                                            <div v-if="vModel.img_mobile">
                                                <img :src="'/images/banners/'+vModel.img_mobile" class="img-fluid"/>
                                                <input type="hidden" name="current_img_mobile" :value="vModel.img_mobile">
                                            </div>
                                            <div class="input-group mb-12">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" id="inputGroupFileAddon01">Cargar</span>
                                                </div>
                                                <div class="custom-file">
                                                    <input name="img_mobile" class="custom-file-input" id="inputGroupFile01" type="file" aria-describedby="inputGroupFileAddon01"/>
                                                    <label class="custom-file-label" for="inputGroupFile01">
                                                        Elegir Imagen *.JPEG
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                            <!--  end::form -->
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancelar</button>
                            <button class="btn btn-success ml-2" type="button" @click="onUpdateBanner()">Añadir</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end::modal ADD BANNER-->

            <!--  Modal IMAGE -->
            <div class="modal fade" id="show-image-banner" tabindex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel"
                 aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                                Imagen ID: <strong v-if="modalImg.img" class="text-danger">{{ modalImg.img }}</strong>
                            </h5>
                            <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
                                aria-hidden="true">×</span></button>
                        </div>
                        <div class="modal-body">
                            <img v-if="modalImg.img" :src="'/images/banners/'+modalImg.img" class="img-fluid"/>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-secondary" type="button" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mb-4">
                <div class="card-body">
                    <div class="card-title mb-3"><i class="fa fa-award"></i> Sección IV - Banner Fijo</div>
                    <p>Aquí encontrarás cada una de las plantillas que te guiarán en el diseño de un nuevo Banner Fijo,
                        da click para descargar la <a href="#" target="_blank" class="text-danger"> plantilla para
                            computadores</a> y la <a href="#" target="_blank" class="text-danger"> plantilla para
                            celulares</a></p>
                    <div class="table-responsive table-striped">
                        <div class="table-responsive table-striped">
                            <table class="display table" id="ul-contact-list" style="width:100%">
                                <thead>
                                <tr>
                                    <th>Thumbnail</th>
                                    <th>Título</th>
                                    <th>Texto Breve</th>
                                    <th>Nombre Botón</th>
                                    <th>URL Botón</th>
                                    <th>Acciones</th>
                                </tr>
                                </thead>
                                <tbody>
                                <!--- Inicia ITEM-->
                                <!--- Inicia ITEM-->
                                <tr>
                                    <td><a href="">
                                        <!----Se muestra la imágen de celulares como THUMBNAIL-->
                                        <div class="ul-widget-app__profile-pic">
                                            <img class="avatar-lg" :src="'/images/banners/'+banner.img_mobile"/>
                                        </div>
                                    </a></td>
                                    <td style="padding-top: 40px;">{{ banner.title }}</td>
                                    <td style="padding-top: 40px;">{{ banner.description }}</td>
                                    <td style="padding-top: 40px;">{{ banner.title_button }}</td>
                                    <td style="padding-top: 40px;"><a :href="banner.url_button" target="_blank">Click
                                        para abrir</a></td>
                                    <td style="padding-top: 28px;">
                                        <!-- BOTÓN MODAL Editar Banner-->
                                        <button class="btn btn-warning btn-md m-1" type="button" data-toggle="modal"
                                                data-target="#add-banner" title="Editar Deslizable"
                                                @click="updateBanner(banner)">
                                            <i class="fa fa-edit text-white"></i>
                                        </button>
                                        <!-- BOTÓN MODAL IMAGEN Full-->
                                        <button class="btn btn-primary btn-md m-1" type="button" data-toggle="modal"
                                                data-target="#show-image-banner" title="Ver imagen Computadores"
                                                @click="openModalImg(banner.image_full)">
                                            <i class="fa fa-desktop text-white"></i>
                                        </button>
                                        <!-- BOTÓN MODAL Imagen Medium-->
                                        <button class="btn btn-primary btn-md m-1" type="button" data-toggle="modal"
                                                data-target="#show-image-banner" title="Ver imagen Celuares"
                                                @click="openModalImg(banner.img_mobile)">
                                            <i class="fa fa-mobile-alt text-white"></i>
                                        </button>
                                        <!-- BOTÓN MODAL QUITAR RESPONSABLE
                                        <button class="btn btn-danger btn-md m-1" type="button" data-toggle="modal"
                                                data-target="#delete-slider" title="Eliminar Deslizable"><i
                                            class="fa fa-trash text-white"></i></button>-->
                                    </td>
                                </tr>
                                <!--- Termina Item-->

                                <!--- Termina Item-->
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<style scoped>
#editor_ban {
    height: 250px; /* Ajusta este valor a la altura que desees */
}
</style>
