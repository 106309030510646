import {BaseService} from "./base.service";

class ClientsService extends BaseService {

    constructor() {
        super('api/clients')
    }

    getClients() {
        return this.http.get('api/get-clients').then(response => response.data).catch(err => err.message);

    }

    updateClient(id, data) {
        return this.http.post('api/update-client/' + id, data).then(response => response.data).catch(err => err.message);
    }

}

export const clientsService = new ClientsService();
