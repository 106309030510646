import {initializeAlgoliaSearch} from './algolia';
//---- INICIA CONFIGURACION DE VUE 3 ----//
import './bootstrap';
import {createApp} from 'vue';
import CatalogueComplete from './catalogue-complete.vue';
import AddUsers from "./components/Manager/Admin/AddUsers.vue";
import ListProducts from "./components/Manager/Products/ListProducts.vue";
import AddProduct from "./components/Manager/Products/AddProducts.vue";
import EditProduct from "./components/Manager/Products/AddProducts.vue";
import SpecificationProducts from "./components/Manager/Specifications/SpecificationProduct.vue";
import SeoProduct from "./components/Manager/Seos/SeoProduct.vue";
import GalleryProducts from "./components/Manager/Products/GalleryProducts.vue";
import RelationProducts from "./components/Manager/Products/RelationProducts.vue";
import MainContainer from "./components/main-container.vue";
import Subscription from './subscription.vue';
import Tag from './components/Manager/Tags/Tag.vue';
import Brand from './components/Manager/Brands/Brand.vue';
import Categories from './components/Manager/Categories/Categories.vue';
import SpecificationConfig from './components/Manager/Specifications/SpecificationConfig.vue';
import SpecificationHeaders from './components/Manager/Specifications/SpecificationHeaders.vue';
import SpecificationConcepts from './components/Manager/Specifications/SpecificationConcepts.vue';
import Orders from './components/Manager/Sales/Orders.vue';
import OrderDetail from './components/Manager/Sales/OrderDetail.vue';
import HomeConfig from './components/Manager/Home/Home-configuration.vue';
import HomeSlider from './components/Manager/Home/partials/HomeSlider.vue';
import Diferenciadores from "./components/Manager/Home/partials/Diferenciadores.vue";
import FeatureProducts from "./components/Manager/Home/partials/ProductosDestacados.vue";
import BannerFijo from "./components/Manager/Home/partials/BannerFijo.vue";
import ProductosDestacadosPorCategorias from "./components/Manager/Home/partials/ProductosDestacadosPorCategoria.vue";
import InformacionRelevante from "./components/Manager/Home/partials/InformacionRelevante.vue";
import Settings from "./components/Manager/Settings/Settings.vue";
import InfoPages from "./components/Manager/InfoPages/InfoPages.vue";
import EditInfoPages from "./components/Manager/InfoPages/EditInfoPages.vue";
import AddInfoPages from "./components/Manager/InfoPages/EditInfoPages.vue";
import SettingFaqs from "./components/Manager/Settings/Faqs.vue";
import Subscribers from "./components/Manager/Subscriptions/Subscribers.vue";
import Clients from "./components/Manager/Clients/Clients.vue";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

//EMPIEZA CONFIGURACION DE VUE 3
const app = createApp({
    // Tus opciones de aplicación aquí
});

app.component('main-container', MainContainer);
app.component('catalogue-complete', CatalogueComplete);
app.component('subscription-complete', Subscription);
app.component('add_users', AddUsers);
app.component('list_products', ListProducts);
app.component('add_products', AddProduct);
app.component('edit_product', EditProduct);
app.component('specification_products', SpecificationProducts);
app.component('seo_product', SeoProduct);
app.component('gallery_product', GalleryProducts);
app.component('relation_products', RelationProducts);
app.component('tags', Tag);
app.component('brands', Brand);
app.component('categories', Categories);
app.component('specifications_headers', SpecificationHeaders);
app.component('specifications_concepts', SpecificationConcepts);
app.component('specifications_config', SpecificationConfig);
app.component('orders', Orders);
app.component('order_detail', OrderDetail);
app.component('home_config', HomeConfig);
app.component('home_slider', HomeSlider);
app.component('differentiators', Diferenciadores);
app.component('feature_products', FeatureProducts);
app.component('banner_fijo', BannerFijo);
app.component('productos_destacados_por_categorias', ProductosDestacadosPorCategorias);
app.component('informacion_relevante', InformacionRelevante);
app.component('settings', Settings);
app.component('info_pages', InfoPages);
app.component('edit_info_page', EditInfoPages);
app.component('add_info_page', AddInfoPages);
app.component('setting_faqs', SettingFaqs);
app.component('subscribers', Subscribers);
app.component('clients', Clients);

app.use(VueSweetalert2);
app.mount('#app'); // Asegúrate de que este sea el id de tu elemento raíz de Vue en tu HTML

//---- INICIA ALGOLIA ----//
document.addEventListener('DOMContentLoaded', function () {
    const searchBoxElement = document.querySelector('#desktop-searchbox');
    if (searchBoxElement) {
        initializeAlgoliaSearch();
    }
});

