<script setup>
import {ref, onMounted, nextTick, reactive} from 'vue';
import {gralHook} from "../../../../Hooks/gralHook.js";
import {homeRelevantInfoService} from "../../../../services/HomeRelevantInfo.service";

const {getAlert, valFormularios, getFormData} = gralHook();

const posts = ref([]);

const modificar = ref(false);

const vModel = ref({
    id: 0,
    title: '',
    subtitle: '',
    description: '',
    image: ''
});

const onSave = async () => {
    let resp = '';
    resp = valFormularios('crudFormRel');
    if (resp) {
        const formData = getFormData('crudFormRel');
        if (modificar.value) {
            resp = await homeRelevantInfoService.updateOne(vModel.value.id, formData, {isForm: true});
        } else {
            resp = await homeRelevantInfoService.saveOne(formData, {isForm: true});
        }
        getAlert(resp.process);
    }
};

const onIndex = async () => {
    posts.value = await homeRelevantInfoService.getMany();
};

let modalImg = reactive({
    img: ''
});

const updateInfo = (post) => {
    try {
        modificar.value = true;
        vModel.value = post;
    } catch (error) {
        console.error('Error al actualizar el slider:', error);
    }
}

const openModalImg = async (img) => {
    modalImg.img = img;
    await nextTick();
}

onMounted(async () => {
    await onIndex();
});

</script>

<template>
    <section>
        <!--  Modal -->
        <div class="modal fade" id="show-image-rel" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Imagen ID:
                            <strong v-if="modalImg.img" class="text-danger">
                                {{ modalImg.img }}
                            </strong>
                        </h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">×</span></button>
                    </div>
                    <div class="modal-body">
                        <img v-if="modalImg.img" :src="'/images/slides/'+modalImg.img" class="img-fluid"/>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" type="button" data-dismiss="modal">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- begin::modal ADD RELEVANT INFO-->
        <div id="add-relevant-info" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
             aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalCenterTitle">Añadir Información Relevante</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">×</span></button>
                    </div>
                    <div class="modal-body">
                        <!--  start card -->

                        <!-- begin::form-->
                        <form id="crudFormRel" class="crudFormRel">
                            <div class="card-body">
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <label class="ul-form__label" for="">Título:</label>
                                        <textarea name="title" v-model="vModel.title" class="form-control"
                                                  id="exampleFormControlTextarea1" rows="3"
                                                  placeholder="Título de la Información Relevante"></textarea>
                                        <!--- Aquí deberían poder hacer el salto de línea un Rich-Text-->
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <label class="ul-form__label" for="">Subtítulo:</label>
                                        <input name="subtitle" v-model="vModel.subtitle" class="form-control" id=""
                                               type="text" placeholder="Subtitulo relevante"/>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <label class="ul-form__label" for="">Texto Breve:</label>
                                        <input name="description" v-model="vModel.description" class="form-control"
                                               id="" type="text" placeholder="Breve texto"/>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group  col-md-12">
                                        <label class="ul-form__label" for="">
                                            Imagen Representativa (730 px * 490 px):
                                        </label>
                                        <div v-if="vModel.image">
                                            <input type="hidden" name="current_image" :value="vModel.image">
                                            <img width="100px" :src="'/images/posts/'+vModel.image" class="img-fluid" alt=""/>
                                            <br>
                                        </div>
                                        <div class="input-group mb-12">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroupFileAddon01">Cargar</span>
                                            </div>
                                            <div class="custom-file">
                                                <input name="image" class="custom-file-input" id="inputGroupFile01"
                                                       type="file" aria-describedby="inputGroupFileAddon01"/>
                                                <label class="custom-file-label" for="inputGroupFile01">
                                                    Elegir Imagen *.JPEG
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!--  end::form -->
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancelar</button>
                        <button class="btn btn-success ml-2" type="button" @click="onSave()">Añadir</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- end::modal ADD Relevant Info-->

        <div class="col-md-12">
            <div class="card mb-4">
                <div class="card-header text-right bg-transparent" style="padding-top: 3em;">
                    <button class="btn btn-success btn-md m-1" type="button" data-toggle="modal"
                            data-target="#add-relevant-info"><i class="fa fa-info text-white mr-2"></i> Agregar
                        Información Relevante
                    </button>
                </div>
                <div class="card-body">
                    <div class="card-title mb-3"><i class="fa fa-clone"></i> Sección VI - Información Relevante</div>
                    <p>Aquí encontrarás la plantilla que te guiará en el diseño de nuevas imágenes para la información
                        relevante, da click para descargar la <a href="#" target="_blank" class="text-danger">
                            plantilla </a></p>
                    <div class="table-responsive table-striped">
                        <table class="display table" id="ul-contact-list" style="width:100%">
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Thumbnail</th>
                                <th>Título</th>
                                <th>Subtítulo</th>
                                <th>Texto Breve</th>
                                <th>Acciones</th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="post in posts" :key="post.id">
                                <!--- Inicia ITEM-->
                                <tr>
                                    <td style="padding-top: 40px;">{{ post.id }}</td>
                                    <td><a href="">
                                        <!---- THUMBNAIL-->
                                        <div class="ul-widget-app__profile-pic">
                                            <img class="avatar-lg" :src="'/images/posts/'+post.image"/>
                                        </div>
                                    </a></td>
                                    <td style="padding-top: 40px;">
                                        {{ post.title }}
                                    </td>
                                    <td style="padding-top: 40px;">
                                        {{ post.subtitle }}
                                    </td>
                                    <td style="padding-top: 40px;">
                                        {{ post.description }}
                                    </td>
                                    <td style="padding-top: 28px;">
                                        <!-- BOTÓN MODAL Editar-->
                                        <button class="btn btn-warning btn-md m-1" type="button" data-toggle="modal"
                                                data-target="#add-relevant-info" title="Editar Información Relevante"
                                                @click="updateInfo(post)">
                                            <i class="fa fa-edit text-white"></i>
                                        </button>
                                        <!-- BOTÓN MODAL IMAGEN-->
                                        <button class="btn btn-primary btn-md m-1" type="button" data-toggle="modal"
                                                data-target="#add-relevant-info" title="Ver imagen"
                                                @click="openModalImg(post.image)">
                                            <i class="fa fa-image text-white"></i>
                                        </button>
                                        <!-- BOTÓN MODAL QUITAR Información Relevante-->
                                        <button class="btn btn-danger btn-md m-1" type="button" data-toggle="modal"
                                                data-target="#delete-relevant-info"
                                                title="Eliminar Información Relevante">
                                            <i class="fa fa-trash text-white"></i>
                                        </button>
                                    </td>
                                </tr>
                                <!--- Termina Item-->
                            </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<style scoped>

</style>
