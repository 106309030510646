<script setup>
import {ref, onMounted} from 'vue';
import {clientsService} from '../../../services/Clients.service';
import {gralHook} from "../../../Hooks/gralHook";
import sweetAlert from "sweetalert2";

const {getAlert, valFormularios, goBack} = gralHook();

const modificar = ref(false);

const client = ref({
    id: null,
    names: '',
    lastnames: '',
    whatsapp: '',
    is_guest: '',
});

const clients = ref([]);

const onSave = async () => {
    let resp;
    resp = valFormularios('crudForm');
    if (resp) {
        if (modificar.value) {
            resp = await clientsService.updateClient(client.value.id, client.value);
        } else {
            //resp = await clientsService.saveOne(client.value);
        }
        getAlert(resp.process);
    }
};

const onUpdate = (subs) => {
    modificar.value = true;
    client.value = {...subs};
};

const onDelete = async (id) => {
    sweetAlert.fire({
        title: 'Esta seguro de esta operación?',
        text: "No podrás revertir esto.!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, borrarlo!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            let resp = await clientsService.deleteOne(id);
            getAlert(resp.process);
            await onIndex();
        }
    })
};

onMounted(async () => {
    clients.value = await clientsService.getClients();
});

</script>
<template>
    <section class="contact-list">
        <!-- begin::modal Faqs -->
        <div id="client" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
             aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalCenterTitle">Cliente</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">×</span></button>
                    </div>
                    <div class="modal-body">

                        <form id="crudForm" class="crudForm">
                            <div class="card-body">
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label class="ul-form__label" for="">Nombre:</label>
                                        <input v-model="client.names" name="names" class="form-control required"
                                               id="" type="text"
                                               placeholder="Breve texto"/>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="ul-form__label" for="">Apellidos:</label>
                                        <input v-model="client.lastnames" name="lastnames" class="form-control required"
                                               id="" type="text"
                                               placeholder="Breve texto"/>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <label class="ul-form__label" for="">Whatsapp:</label>
                                        <input v-model="client.whatsapp" name="whatsapp" class="form-control required"
                                               id="" type="text"
                                               placeholder="Breve texto"/>
                                    </div>
                                </div>

                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancelar</button>
                        <button class="btn btn-success ml-2" type="button" @click="onSave()">
                            Añadir
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="breadcrumb">
            <h1 class="mr-2">Cliente</h1>
        </div>
        <div class="separator-breadcrumb border-top"></div>

        <div class="row">
            <div class="col-md-12 mb-4">
                <div class="card-header text-right bg-transparent">
                    <!--
                    <button class="btn btn-success btn-md m-1" type="button" data-toggle="modal"
                            data-target="#client">
                        <i class="fa fa-newspaper text-white mr-2"></i>
                        Agregar Página de Información
                    </button>
                   -->

                </div>
                <div class="card text-left">
                    <div class="card-body">
                        <div class="table-responsive table-striped">
                            <table class="display table" id="ul-contact-list" style="width:100%">
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Whatsapp</th>
                                    <th>Es invitado</th>
                                    <th>Acciones</th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="(client) in clients" :key="client.id">

                                    <tr class="drag">
                                        <td style="vertical-align: middle !important;">{{ client.id }}</td>
                                        <td style="vertical-align: middle !important;">{{ client.names }} {{ client.lastnames }}</td>
                                        <td style="vertical-align: middle !important;">{{ client.whatsapp }}</td>
                                        <td style="vertical-align: middle !important;">
                                            <span v-if="client.is_guest==2">Invitado </span>
                                            <span v-else>Cliente</span>
                                        </td>
                                        <td style="vertical-align: middle !important;">

                                            <button class="btn btn-warning btn-md m-1"
                                                    type="button"
                                                    data-toggle="modal"
                                                    data-target="#client"
                                                    title="Editar Categoría" @click="onUpdate(client)">
                                                <i class="fa fa-edit text-white"></i>
                                            </button>

<!--                                            <button class="btn btn-danger btn-md m-1"
                                                    type="button"
                                                    data-toggle="modal" data-target="#delete-category"
                                                    title="Eliminar Categoría" @click="onDelete(subs.id)"><i
                                                class="fa fa-trash text-white"></i></button>-->
                                        </td>
                                    </tr>

                                </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


</template>

<style scoped>

</style>
