<script setup>
import {ref, onMounted} from 'vue';
import {gralHook} from "../../../Hooks/gralHook.js";
import {seosService} from "../../../services/Seos.service";

const {getAlert, valFormularios, goBack} = gralHook();
const props = defineProps({
    product_id: {
        type: Number,
        required: true,
    }
});
const seos = ref([]);
const vModel = ref({
    product_id: props.product_id,
    meta_title: '',
    meta_keywords: '',
    meta_description: '',
});

const onUpdate = async () => {
    let resp;
    resp = await seosService.updateOne(vModel.value.product_id, vModel.value);
    getAlert(resp.process, resp.error);
}

onMounted(async () => {
    // Cargar datos
    seos.value = await seosService.getOne(props.product_id);
    vModel.value.meta_title = seos.value.meta_title;
    vModel.value.meta_keywords = seos.value.meta_keywords;
    vModel.value.meta_description = seos.value.meta_description;
});
</script>

<template>
    <div>
        <div class="card-title mb-3"><i class="fa fa-cog"></i> SEO para motores de búsqueda</div>
        <form>
            <div class="row">
                <div class="form-group col-md-6">
                    <label class="ul-form__label" for="">Título de la Página del Producto:</label>
                    <input class="form-control" v-model="vModel.meta_title" type="text"
                           placeholder="El título que aparece en los Motores"/>
                </div>
                <div class="form-group col-md-6">
                    <label class="ul-form__label" for="">Palabras Clave del Producto: <small class="text-danger">(Separados por comas y sin espacio entre las comas)</small></label>
                    <input class="form-control" v-model="vModel.meta_keywords" type="text"
                           placeholder="Las palabras que escribe el usuario para encontrar tu página, separados por comas"/>
                </div>
                <div class="form-group col-md-12 my-2">
                    <label class="ul-form__label" for="">Descripción del Producto:</label>
                    <input class="form-control" v-model="vModel.meta_description" type="text"
                           placeholder="Hasta 250 carácteres"/>
                </div>
            </div>
            <div class="modal-footer my-4"><!--- Línea División-->
                <button class="btn btn-success ml-2" type="button" @click="onUpdate()"><i
                    class="fa fa-tag text-white mr-2"></i> Guardar
                </button>
            </div>
        </form>
    </div>
</template>

<style scoped>

</style>
