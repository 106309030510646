<script setup>
import {ref, onMounted, watch} from 'vue';
import {gralHook} from "../../../Hooks/gralHook.js";
import Quill from "quill";
import {settingInfosService} from "../../../services/SettingInfos.service";
import {settingsService} from "../../../services/Settings.service";

//DATA
const {getAlert, valFormularios, getFormData, generateFriendlyUrl} = gralHook();

const vModel = ref({
    image_banner: '',
    title: '',
    text: '',
    image_text: '',
    image_caption: '',
    title_section: '',
    slug: ''
});

let site_url = ref('');

const props = defineProps({
    pageId: {
        type: [String, Number],
        default: null,
    },
});

const modificar = ref(false);

let quill = null;

//METHODS
const onIndex = async () => {
    let _settings = await settingsService.getMany();

    for (let setting of _settings) {
        if (setting.key === 'site_url') {
            site_url.value = setting.value;
            break;
        }
    }
    quill = new Quill("#editor", {
        theme: "snow",
    });

    if (props.pageId) {
        console.log('existe el prop = ' + props.pageId);
        vModel.value = await settingInfosService.getOne(props.pageId);
        modificar.value = true;
        quill.root.innerHTML = vModel.value.text;
    }
};

const onSave = async () => {
    let resp;
    resp = valFormularios('crudForm');
    const formData = getFormData('crudForm');
    formData.append('text', quill.root.innerHTML);
    if (resp) {
        if (modificar.value) {
            resp = await settingInfosService.updateOne(vModel.value.id, formData, {isForm: true});
        } else {
            resp = await settingInfosService.saveOne(formData, {isForm: true});
        }
        getAlert(resp.process, resp.error);
    }
};

onMounted(async () => {
    await onIndex();
});

watch(() => vModel.value.title_section, (newVal) => {
    vModel.value.slug = generateFriendlyUrl(newVal);
});


</script>

<template>
    <div class="main-content">
        <div class="breadcrumb">
            <h1 class="mr-2">Página Informativa</h1>
        </div>
        <div class="separator-breadcrumb border-top"></div><!--- LINEA DE DIVISIÓN-->

        <!-- BOTÓN CERRAR VENTANA --->
        <div class="card-header text-right bg-transparent">
            <button class="btn btn-danger btn-md m-1" type="button" onclick="">
                <i class="fa fa-angle-left text-white mr-2"></i>
                Regresar
            </button>
        </div>
        <section>
            <div>
                <div class="col-md-12">
                    <div class="card mb-4">
                        <div class="card-body">
                            <div class="card-title mb-3"><i class="fa fa-newspaper"></i> Página Informativa</div>
                            <form id="crudForm" class="crudForm">
                                <div class="row">

                                    <div class="form-group  col-md-12">
                                        <label class="ul-form__label" for="">Imagen Banner (Portada):</label>
                                        <!---image_banner-->
                                        <p>
                                            <img width="90" :src="'../../images/banners/'+vModel.image_banner"
                                                 alt="">
                                        </p>
                                        <div class="input-group mb-12">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroupFileAddon01">Cargar</span>
                                            </div>

                                            <div class="custom-file">
                                                <input type="hidden" name="current_image_banner"
                                                       v-model="vModel.image_banner">

                                                <input class="custom-file-input" name="image_banner"
                                                       id="inputGroupFile01" type="file"
                                                       aria-describedby="inputGroupFileAddon01"/>
                                                <label class="custom-file-label" for="inputGroupFile01">
                                                    Elegir Archivo *.PNG , *.JPEG, *.JPG
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label class="ul-form__label" for="">Título de la Sección: (Así acceden los
                                            usuarios a esta página)</label>
                                        <!---title_section-->
                                        <input class="form-control" name="title_section" type="text"
                                               placeholder="Texto" v-model="vModel.title_section"  :disabled="(props.pageId===1||props.pageId===2||props.pageId===3)"/>
                                        <!--- ESTE CAMPO ESTÁ EN disabled para los ID 1 Y ID 2-->
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="ul-form__label" for="">Título del Texto: (El texto que aparece en
                                            la parte superior de la página)</label>
                                        <!--title-->
                                        <input class="form-control" name="title" v-model="vModel.title" type="text"
                                               placeholder="Texto"/>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label class="ul-form__label" for="">URL Amigable (No usar carácteres
                                            epeciales):</label>
                                        <!--slug-->
                                    </div>
                                    <div class="input-group col-md-12">
                                        <!---slug-->
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon3">
                                                {{ site_url }}/pagina/
                                            </span>
                                        </div>
                                        <input class="form-control" name="slug" v-model="vModel.slug" id="basic-url"
                                               type="text"
                                               aria-describedby="basic-addon3"
                                               :disabled="(props.pageId===1||props.pageId===2||props.pageId===3)"
                                               placeholder="Puede llevar guiones - SIN carácteres especiales (, . + / & % ´´ ), sólo palabras, sin acentos"/>
                                        <!--- ESTE CAMPO ESTÁ EN disabled para los ID 1 Y ID 2-->
                                    </div>
                                    <div class="form-group  col-md-12 mt-4">
                                        <!--- RICH TEXT-->
                                        <label class="ul-form__label" for="">Texto Completo:</label>
                                        <!---text--->
                                        <div id="editor" class="mb-4"></div>
                                    </div>
                                    <div class="form-group  col-md-12">
                                        <label class="ul-form__label" for="">Imagen Pie de Página:</label>
                                        <!----image_text-->
                                        <p>
                                            <img width="90" :src="'../../images/banners/'+vModel.image_text"
                                                 alt="">
                                        </p>
                                        <div class="input-group mb-12">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroupFileAddon01">Cargar</span>
                                            </div>

                                            <div class="custom-file">

                                                <input type="hidden" name="current_image_text"
                                                       v-model="vModel.image_text">
                                                <input class="custom-file-input" name="image_text" id="inputGroupFile01"
                                                       type="file" aria-describedby="inputGroupFileAddon01"/>
                                                <label class="custom-file-label" for="inputGroupFile01">Elegir Archivo
                                                    *.PNG , *.JPEG, *.JPG</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12 my-2">
                                        <label class="ul-form__label" for="">Texto - Pie de Foto:</label>
                                        <!----imgage_caption-->
                                        <input class="form-control" v-model="vModel.image_caption" name="image_caption"
                                               type="text"
                                               placeholder="Texto Breve"/>
                                    </div>
                                </div>
                                <div class="modal-footer my-4"></div><!--- Línea División-->
                                <button class="btn btn-success ml-2" type="button" @click="onSave()"><i
                                    class="fa fa-newspaper text-white mr-2"></i> Guardar
                                </button>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </section>


        <!-- end of main-content -->
    </div>
</template>

<style scoped>
#editor {
    height: 250px; /* Ajusta este valor a la altura que desees */
}
</style>
