<script setup>
import {ref, onMounted, watch} from 'vue';
import {categoriesService} from '../../../../services/Categories.service';
import {vsproductcategoryService} from '../../../../services/VsProductCategory.service';
import {gralHook} from "../../../../Hooks/gralHook";
import {productsService} from "../../../../services/Products.service";
import Multiselect from '@vueform/multiselect';

const {getAlert, valFormularios, getFormData} = gralHook();

const categories = ref([]);
const filterProducts = ref([])
const getListProduct = ref([]);
const theProduct = ref([]);
const category = ref(0);
const categoryName = ref('');

const onSave = async () => {
    let data = {
        category_id: category.value,
        products: getListProduct.value.map(pdcto => pdcto.value)
    }
    let resp = await vsproductcategoryService.saveOne(data);
    getAlert(resp.process);
}

const params = ref({
    where: [{
        field: 'category_id',
        operator: '=',
        value: 0
    }]
});

const getFeaturedProducts = async () => {
    params.value.where[0].value = category.value;
    theProduct.value = await vsproductcategoryService.getMany(params.value);
    filterProducts.value = theProduct.value
        .filter(pdcto => pdcto.product.type === 1)
        .map(pdcto => ({
            value: pdcto.product.id,
            label: pdcto.product.name
        }));

    getListProduct.value = theProduct.value
        .filter(product => product.is_featured === 1)
        .map((product) => ({
            value: product.product.id,
            label: product.product.name
        }));
}

onMounted(async () => {
    categories.value = await categoriesService.getMany();
});

watch(category, (newVal) => {
    const selectedCategory = categories.value.find(cat => cat.id === newVal);
    categoryName.value = selectedCategory ? selectedCategory.name : '';
});
</script>

<template>
    <section>

        <div class="col-md-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="card-title mb-3"><i class="fa fa-tag"></i> Sección V - Productos Destacados por
                        Categoría
                    </div>
                    <p>Selecciona una categoría, al hacerlo se actualizará la lista para que puedas agregar o quitar
                        productos que mostrar </p>
                    <form>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label class="ul-form__label" for="">Categoría:</label>
                                <div class="input-right-icon">
                                    <select v-model="category" style="background:#f0d06e;" class="form-control"
                                            @change="getFeaturedProducts()">
                                        <option :value="0">Seleccionar</option>
                                        <option v-for="cat in categories" :value="cat.id">{{ cat.name }}</option>
                                    </select>
                                    <span class="span-right-input-icon">
                                                        <i class="ul-form__icon fas fa-tag"></i>
                                                    </span>
                                    <small class="ul-form__text form-text" id="">
                                        Seleccione una jerarquía para este nuevo adminsitrador
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-12">
                                <fieldset>
                                    <label class="ul-form__label" for="">
                                        Productos Destacados para la Cateogoría
                                        <strong v-if="category!==0" class="text-danger" style="font-size: 1.5em;">
                                            {{ categoryName }}
                                        </strong>
                                    </label>
                                    <div class="form-group">
                                        <Multiselect
                                            v-model="getListProduct"
                                            mode="tags"
                                            :close-on-select="false"
                                            :searchable="true"
                                            :object="true"
                                            :resolve-on-load="false"
                                            :options="filterProducts"
                                        />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div class="modal-footer my-4"><!--- Línea División-->
                            <button class="btn btn-success ml-2" type="button" @click="onSave()">
                                <i class="fa fa-tag text-white mr-2"></i>
                                Guardar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </section>
</template>

<style scoped>

</style>
