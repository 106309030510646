<script setup>
import {onMounted, ref, watch, toRef} from 'vue';
import {gralHook} from "../../../Hooks/gralHook.js";
import {ordersService} from "../../../services/Orders.service";
import changeStatusOrder from "../../../components/Manager/widgets/ChangeStatus.vue";
import comments from "../widgets/Comments.vue";
import changeAddress from "../widgets/ChangeAddress.vue";

const {formatDate} = gralHook();
const props = defineProps({
    orderId: {
        type: [Number],
        default: null,
    },
});

const oId = ref(0);
const detailOrder = ref([]);
const RelClient = ref([]);
const RelAddress = ref([]);
const ClientOrderDetail = ref([]);
const RelCityAddress = ref('');
const RelStateAddress = ref('');
const client_notes = ref('');
const admin_notes = ref('');
const client_id = ref(0);

//METHODS
const onIndex = async (id) => {
    detailOrder.value = await ordersService.getOne(id);
    //console.log('detailOrder', detailOrder.value);
    admin_notes.value = detailOrder.value.admin_note;
    client_notes.value = detailOrder.value.client_note;
    client_id.value = detailOrder.value.client_id;
    RelClient.value = detailOrder.value.rel_client;
    RelAddress.value = detailOrder.value.client_address;
    RelCityAddress.value = detailOrder.value.client_address.rel_city.ciudad;
    RelStateAddress.value = detailOrder.value.client_address.rel_state.estado;
    ClientOrderDetail.value = detailOrder.value.client_order_detail;
};

onMounted(async () => {
});

watch(() => props.orderId, async (newVal) => {
    //console.log('watch', newVal);
    oId.value = newVal;
    await onIndex(newVal);
});

</script>

<template>
    <!-- ============ Body content start ============= -->
    <div class="main-content">
        <changeAddress :client-id="client_id" :order-id="oId"></changeAddress>
        <changeStatusOrder :order-id="oId" :client-id="client_id"></changeStatusOrder>
        <comments :admin_notes="admin_notes" :client_notes="client_notes" :order-id="oId"></comments>
        <div class="breadcrumb">
            <h1 class="mr-2">Detalle del Pedido</h1>
        </div>
        <div class="separator-breadcrumb border-top"></div><!--- LINEA DE DIVISIÓN-->

        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="invoice" role="tabpanel"
                             aria-labelledby="invoice-tab">
                            <div class="d-sm-flex mb-5" data-view="print">
                                <span class="m-auto"></span>
                                <button class="btn btn-success btn-md m-1" type="button" data-toggle="modal"
                                        data-target="#order-status" title="Cambiar Estatus">
                                    <i class="fa fa-random"></i>
                                    Cambiar Estatus
                                </button>
                            </div>
                            <!-- -===== Print Area =======-->
                            <div id="print-area">
                                <div class="row">
                                    <div class="col-md-6">
                                        <h4 class="heading text-primary">Información del Pedido</h4>
                                        <p><strong>ID: </strong>{{ detailOrder.id }}</p>
                                        <p><strong>UID: </strong>AX9F</p>
                                    </div>
                                    <div class="col-md-6 text-sm-right">
                                        <p>
                                            <strong>Estatus del Pedido: </strong>
                                            <span v-if="detailOrder.status_order===1">Pendiente de Pago</span>
                                            <span v-if="detailOrder.status_order===2">Procesando Pedido</span>
                                            <span v-if="detailOrder.status_order===3">Enviada</span>
                                            <span v-if="detailOrder.status_order===4">Anulado</span>

                                        </p>
                                        <p><strong>Fecha de Compra: </strong>{{ formatDate(detailOrder.created_at) }}
                                        </p>
                                    </div>
                                </div>
                                <div class="mt-3 mb-4 border-top"></div>

                                <div class="row">
                                    <div class="col-md-6 mb-3 mb-sm-0">
                                        <h5 class="heading text-primary">Información del Cliente</h5>
                                        <p>{{ RelClient.names }} {{ RelClient.lastnames }}</p>
                                        <p><strong> Correo: </strong>{{ RelClient.email }}</p>
                                        <p><strong> Cumpleaños: </strong>{{ RelClient.birthday }}</p>
                                        <p><strong> Teléfono Contacto: </strong>{{ RelClient.phone }}</p>
                                        <p><strong> Whatsapp: </strong> <a
                                            :href="'https://wa.me/'+RelClient.country_code + RelClient.whatsapp"></a>{{
                                                RelClient.whatsapp
                                            }}
                                        </p>
                                    </div>
                                    <div class="col-md-6 text-sm-right">
                                        <h5 class="heading text-primary">Dirección de Envío</h5>
                                        <button class="btn btn-warning btn-md m-1" type="button" data-toggle="modal"
                                                data-target="#order-address" title="Cambiar Dirección"><i
                                            class="fa fa-truck"></i> Cambiar Dirección
                                        </button>
                                        <p class="pt-3">{{ RelAddress.identifier }}</p>
                                        <p>
                                            <strong> Dirección: </strong>
                                            {{ RelAddress.address }}
                                        </p>
                                        <p>
                                            <strong> Colonia: </strong> {{ RelAddress.neighborhood }}
                                            <strong style="padding-left: 0.5em;">Código Postal: </strong>
                                            {{ RelAddress.zip_code }}
                                        </p>
                                        <p>{{ RelCityAddress }}, {{ RelStateAddress }} </p>
                                        <p>
                                            <strong> Recibe: </strong>{{ RelAddress.contact_name }}
                                            <strong style="padding-left: 0.5em;"> Tel: </strong>
                                            {{ RelAddress.contact_phone }}
                                        </p>
                                    </div>
                                </div>
                                <div class="row justify-content-center mb-4">
                                    <div class="col-md-5 mb-6 mb-sm-0">
                                        <p>Comentarios del Cliente</p>
                                        <input class="form-control" :value="client_notes" id="billFrom2" type="text" placeholder="asdf" disabled/>
                                    </div>
                                    <div class="col-md-5 mb-6 mb-sm-0">
                                        <p>Comentarios Internos:</p>
                                        <input class="form-control" :value="admin_notes" type="text" placeholder="Nombre" disabled/>
                                    </div>
                                    <div class="col-md-2 mt-4">
                                        <button class="btn btn-info btn-md m-1" type="button"
                                                data-toggle="modal" data-target="#comments"
                                                title="Ver Comentarios">
                                            <i class="fa fa-comments text-white"></i>
                                        </button>
                                    </div>
                                </div>

                                <!--<div class="row mb-5">
                                        <div class="card-body">
                                            <div class="form-row">
                                                <div class="form-group col-md-11">
                                                    <label class="ul-form__label" for="">Comentarios Internos:</label>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-md-11">
                                                    <input class="form-control" id="" type="text" placeholder="Nombre"/>
                                                </div>
                                                <div class="form-group col-md-1">
                                                    <button class="btn btn-success ml-2" type="button"><i
                                                        class="fa fa-refresh"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>-->

                                <div class="card-title">Lista de Productos del Pedido</div>
                                <div class="table-responsive table-striped">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th scope="col">Producto</th>
                                            <th scope="col">Precio</th>
                                            <th scope="col">Cantidad</th>
                                            <th scope="col">Total</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <template v-for="prod in ClientOrderDetail" :key="prod.id">

                                            <!--- Inicia ITEM-->
                                            <tr>
                                                <td scope="row">
                                                    <img class="profile-picture avatar-sm mb-2 img-fluid"
                                                         :src="'/images/products/'+prod.product.id+'/'+prod.product.img_product"
                                                         alt="alt"/>
                                                    <div class="ul-product-cart__detail d-inline-block align-middle"
                                                         style="padding-left: 0.5em;">
                                                        <a href="javascript:void(0)">
                                                            <h6 class="heading"> {{ prod.product.name }} </h6>
                                                        </a>
                                                        <span class="text-mute"><strong>Categoría: </strong>
                                                        <span v-if="prod.product.vs_product_categories[0]">{{
                                                                prod.product.vs_product_categories[0].name
                                                            }}</span></span><br>
                                                        <span class="text-mute">
                                                        <strong>Tipo: </strong>
                                                        <span v-if="prod.product.type===1">Producto</span>
                                                        <span v-else>Refaccion</span>
                                                    </span>
                                                    </div>
                                                </td>
                                                <td>{{ prod.unit_price }}</td>
                                                <td>{{ prod.quantity }}</td>
                                                <td>{{ prod.total }}</td>
                                            </tr>
                                            <!--- Termina ITEM-->
                                        </template>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12 mt-5">
                                        <div class="ul-product-cart__invoice">
                                            <div class="card-title">
                                                <h4 class="heading text-primary">Pago Total</h4>
                                            </div>
                                            <table class="table">
                                                <tbody>
                                                <tr>
                                                    <th class="text-16" scope="row">Subtotal</th>
                                                    <td class="text-16 font-weight-700">
                                                        {{ detailOrder.subtotal }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th class="text-16" scope="row">Envío</th>
                                                    <td class="text-16 font-weight-700">
                                                        {{ detailOrder.shipping }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th class="text-primary text-16" scope="row">
                                                        Total:
                                                    </th>
                                                    <td class="font-weight-700  text-success text-16">
                                                        {{ detailOrder.total }}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!-- ==== / Print Area =====-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end of main-content -->
    </div>
</template>

<style scoped>

</style>
