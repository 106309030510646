<script setup>
import {ref, onMounted} from 'vue';
import {gralHook} from "../../../Hooks/gralHook.js";
import {tagsService} from "../../../services/Tags.service";
import sweetAlert from "sweetalert2";

const {getAlert, valFormularios, goBack} = gralHook();
const fields = ref([{tag: ''}]);
const tags = ref([{tag: ''}]);
const vModel = ref({
    id: '',
});
const modificar = ref(false);

//METHODS PERSONALIZADOS
const addFields = () => {
    const lastField = fields.value[fields.value.length - 1];
    if (lastField.tag !== '') {
        fields.value.push({tag: ''});
    } else {
        getAlert('empty_tag');
    }
};

const removeField = (index) => {
    fields.value.splice(index, 1);
};

const onDelete = async (id) => {

    sweetAlert.fire({
        title: 'Esta seguro de esta operación?',
        text: "No podrás revertir esto.!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, borrarlo!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            let resp;
            resp = await tagsService.deleteOne(id);
            getAlert(resp.process);
        }
    })

};

const onSave = async () => {
    let resp;
    let dataToSend = {
        tags: fields.value
    };
    resp = valFormularios('crudForm');
    if (resp) {
        if (modificar.value) {
            resp = await tagsService.updateOne(vModel.value.id, dataToSend);
        } else {
            resp = await tagsService.saveOne(dataToSend);
        }
        getAlert(resp.process, resp.error);
    }
};

const updateField = (index, data) => {
    fields.value[0].tag = data.name;
    vModel.value.id = data.id;
    console.log('fields update', fields.value);
    modificar.value = true;
};

const onIndex = async () => {
    tags.value = await tagsService.getMany();
};

onMounted(async () => {
    await onIndex();
});
</script>

<template>
    <div id="add_tag_component">

        <!-- begin::modal ADD TAG-->
        <div id="add-tag" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
             aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalCenterTitle">Añadir Tags</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form id="crudForm" class="crudForm">
                            <!--  start card -->
                            <!-- begin::form-->
                            <div class="row">
                                <div class="form-group col-md-10">
                                    <label class="ul-form__label" for="">Nombre:</label>
                                </div>
                                <div class="form-group col-md-1">
                                    <button class="btn btn-success ml-2" type="button" v-if="!modificar"
                                            @click="addFields">
                                        <i class="fa fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <template v-for="(field, index) in fields" :key="index">
                                <!--- ITEM Start-->
                                <div class="row">
                                    <div class="form-group col-md-10">
                                        <input class="form-control required" v-model="field.tag" type="text"
                                               placeholder="Nombre del Tag"/><small
                                        class="ul-form__text form-text">
                                    </small>
                                    </div>
                                    <div class="form-group col-md-1">
                                        <button class="btn btn-danger ml-2" type="button" @click="removeField(index)"
                                                v-if="index > 0"><i class="fa fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                                <!-- Item End-->
                            </template>
                        </form>
                        <!--  end::form -->
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancelar</button>
                        <button class="btn btn-success ml-2" type="button" @click="onSave">Añadir</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- end::modal ADD TAG-->

        <div class="main-content">
            <div class="breadcrumb">
                <h1 class="mr-2">Tags</h1>
            </div>
            <div class="separator-breadcrumb border-top"></div><!--- LINEA DE DIVISIÓN-->
            <section class="contact-list">
                <div class="row">
                    <div class="col-md-6 mb-4">
                        <div class="card text-left">
                            <div class="card-header text-right bg-transparent">
                                <button class="btn btn-success btn-md m-1" type="button" data-toggle="modal"
                                        data-target="#add-tag">
                                    <i class="fa fa-plus text-white mr-2"></i> Agregar Tag
                                </button>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive table-striped">
                                    <table class="display table" id="ul-contact-list" style="width:100%">
                                        <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Nombre</th>
                                            <th>Acciones</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <!--- Inicia ITEM-->
                                        <template v-for="(tag, index) in tags" :key="index">
                                            <tr>
                                                <td style="padding-top: 40px;">{{ tag.id }}</td>
                                                <td style="padding-top: 40px;">{{ tag.name }}</td>

                                                <td style="padding-top: 28px;">
                                                    <!-- BOTÓN MODAL Editar -->
                                                    <button class="btn btn-warning btn-md m-1" type="button"
                                                            data-toggle="modal" data-target="#add-tag"
                                                            title="Editar Tag" @click="updateField(index, tag)">
                                                        <i class="fa fa-edit text-white"></i>
                                                    </button>
                                                    <!-- BOTÓN MODAL Eliminar  Soft Delete-->
                                                    <button class="btn btn-danger btn-md m-1" type="button"
                                                            title="Eliminar Tag" @click="onDelete(tag.id)">
                                                        <i class="fa fa-trash text-white"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </template>
                                        <!--- Termina Item-->
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section><!-- end of main-content -->


            <!-- end of main-content -->
        </div>
    </div>
</template>

<style scoped>

</style>
