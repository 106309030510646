<script setup>
import {ref, onMounted} from 'vue';
import {productsService} from "../../../services/Products.service";
import {gralHook} from "../../../Hooks/gralHook.js";

//DATA
const {getAlert, valFormularios} = gralHook();

const vModel = ref({
    name: '',
    email: '',
    phone: '',
    role_id: 0,
    password: '',
});

const products = ref([]);

const params = ref({
    where: [{
        field: 'type',
        operator: '=',
        value: 1
    }]
});

let showSearchBlock = ref(false); // Nueva variable reactiva para controlar la visibilidad del bloque de búsqueda

const sku = ref('');

const productName = ref('');
//METHODS

const changeVisibleProduct = async (productId, event) => {
    // Verificar si el checkbox está marcado o no
    const status = event.target.checked ? 1 : 0;

    // Llamar al método productsService.visibleProduct
    const resp = await productsService.visibleProduct(productId, status);

    // Aquí puedes manejar la respuesta de productsService.visibleProduct
    // Por ejemplo, puedes mostrar un mensaje de éxito o error dependiendo de la respuesta
    getAlert(resp.process);
};

const onIndex = async () => {
    const productResult = await productsService.getMany(params.value);
    products.value = Array.isArray(productResult) ? productResult : [];
};

const filterProducts = async () => {
    const filter = document.querySelector('.trashFilter').value;
    switch (filter) {
        case '1':
            params.value.where = [{
                field: 'type',
                operator: '=',
                value: 1
            }];
            showSearchBlock.value = false;
            break;
        case '2':
            params.value.where = [{
                field: 'type',
                operator: '=',
                value: 2
            }];
            showSearchBlock.value = false;
            break;
        default:
            showSearchBlock.value = false;
            params.value.where = [];
            break;
    }

    if (!showSearchBlock.value) {
        const productResult = await productsService.getMany(params.value);
        products.value = Array.isArray(productResult) ? productResult : [];
    }
};

const findProduct = async () => {
    //console.log('sku = ', sku.value);

    params.value.where = []; // Inicializar como array vacío

    if (sku.value !== '') {
        params.value.where.push({
            field: 'sku',
            operator: 'LIKE',
            value: '%' + sku.value + '%'
        });
    }

    if (productName.value !== '') {
        params.value.where.push({
            field: 'name',
            operator: 'LIKE',
            value: '%' + productName.value + '%'
        });
    }

    //console.log(params.value);
    const productResult = await productsService.getMany(params.value);
    products.value = Array.isArray(productResult) ? productResult : [];
};

onMounted(async () => {
    await onIndex();
});


</script>

<template>
    <div id="add_user_component">
        <!-- ============ Body content start ============= -->
        <div class="main-content">
            <div class="breadcrumb">
                <h1 class="mr-2">Lista de Productos</h1>
            </div>
            <div class="separator-breadcrumb border-top"></div><!--- LINEA DE DIVISIÓN-->
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="card mb-5">
                        <div class="card-body">
                            <div class="row" style="padding-left: 20px;"> Filtros</div>
                            <div class="row row-xs my-3">
                                <div class="col-md-10 mt-3 mt-md-0">
                                    <div class="form-group">
                                        <select class="form-control trashFilter" @change="filterProducts()">
                                            <option :value="1">Productos</option>
                                            <option :value="2">Refacciones</option>
                                            <option :value="3">Todos</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-2 mt-3 mt-md-0">
                                    <button class="btn btn-primary btn-block">Mostrar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="card mb-5">
                        <div class="card-body">
                            <div class="row" style="padding-left: 20px;"> Buscar por:</div>
                            <div class="row row-xs my-3">
                                <div class="col-md-3 mt-3 mt-md-0">
                                    <div class="form-group">
                                        <input class="form-control" v-model="sku" type="text" placeholder="SKU"/>
                                        <small class="ul-form__text form-text"></small>
                                    </div>
                                </div>
                                <div class="col-md-3 mt-3 mt-md-0">
                                    <div class="form-group">
                                        <input class="form-control" v-model="productName" type="text"
                                               placeholder="Nombre"/>
                                        <small class="ul-form__text form-text"> </small>
                                    </div>
                                </div>
                                <div class="col-md-2 mt-3 mt-md-0">
                                    <button class="btn btn-primary btn-block" @click="findProduct()">Filtrar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section class="contact-list">
                <div class="row">
                    <div class="col-md-12 mb-4">
                        <div class="text-left">
                            <div class="card-header text-right bg-transparent">
                                <a href="/manager/agregar-producto">
                                    <button class="btn btn-primary btn-md m-1" type="button"><i
                                        class="i-Tag-3 text-white mr-2"></i> Nuevo Producto
                                    </button>
                                </a>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive table-striped">
                                    <table class="display table" id="ul-contact-list" style="width:100%">
                                        <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Thumbnail</th>
                                            <th>SKU</th>
                                            <th>Nombre</th>
                                            <th>Tipo</th>
                                            <th>Precio</th>
                                            <th>Inventario</th>
                                            <th>Acciones</th>
                                            <th>Estatus</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        <template v-for="product in products" :key="product.id">
                                            <!--- Inicia ITEM-->
                                            <tr>
                                                <td style="padding-top: 40px;">{{ product.id }}</td>
                                                <td>
                                                    <a href="">
                                                        <div class="ul-widget-app__profile-pic">
                                                            <img class="avatar-lg"
                                                                 :src="product.img_product === 'noimage.jpg' ? '/images/products/'+product.img_product : '/images/products/'+product.id+'/'+product.img_product"
                                                                 alt=""/>
                                                        </div>
                                                    </a>
                                                </td>
                                                <td style="padding-top: 40px;">{{ product.sku }}</td>
                                                <td style="padding-top: 40px;">{{ product.name }}
                                                </td>
                                                <td style="padding-top: 40px;">
                                                    <template v-if="product.type===1"> Pdto</template>
                                                    <template v-else> Refc.</template>
                                                </td>
                                                <td style="padding-top: 40px;">${{ product.price_public }}</td>
                                                <td style="padding-top: 40px;">{{ product.stock }}</td>
                                                <td style="padding-top: 28px;">
                                                    <!-- BOTÓN MODAL QUITAR RESPONSABLE-->
                                                    <a :href="'/manager/editar-producto/'+product.id">
                                                        <button class="btn btn-warning btn-md m-1" type="button"
                                                                title="Editar Producto"><i
                                                            class="fa fa-edit text-white"></i></button>
                                                    </a>
                                                    <!-- BOTÓN MODAL QUITAR RESPONSABLE-->
                                                    <button class="btn btn-danger btn-md m-1" type="button"
                                                            data-toggle="modal" data-target="#delete-product"
                                                            title="Eliminar Producto"><i
                                                        class="fa fa-trash text-white"></i>
                                                    </button>
                                                </td>
                                                <td style="padding-top: 45px;">
                                                    <label class="switch pr-5 switch-primary mr-3">
                                                        <span>Visible</span>
                                                        <input @change="changeVisibleProduct(product.id, $event)"
                                                               type="checkbox"
                                                               :checked="product.visible_product == 1"/><span
                                                        class="slider"></span>
                                                    </label></td>
                                            </tr>
                                            <!--- Termina Item-->
                                        </template>


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section><!-- end of main-content -->
            <!-- end of main-content -->
        </div>
        <!-- ============ Body content End ============= -->
    </div>
</template>

<style scoped>

</style>
