<script setup>
import {onMounted, ref, watch} from 'vue';
import {gralHook} from "../../../Hooks/gralHook.js";
import {categoriesService} from "../../../services/Categories.service";
import {sparesService} from "../../../services/Spares.service";
import {productsService} from "../../../services/Products.service";
import {tagsService} from "../../../services/Tags.service";
//import Vue3TagsInput from 'vue3-tags-input';
import {vsproductcategoryService} from "../../../services/VsProductCategory.service";
import {vsproductspareService} from "../../../services/VsProductSpares.service";
import {vsrelationproductService} from "../../../services/VsRelationProduct.service";
import {vsproducttagService} from "../../../services/VsProductTags.service";

const {getAlert, valFormularios, goBack} = gralHook();
import Multiselect from '@vueform/multiselect';

const props = defineProps({
    product_id: {
        type: Number,
        required: true,
    },
});

const pID = ref(0);
const modificar = ref(false);
const vModel = ref({});

/*
 * RELACION DE CATEGORIAS
*/
const filterCats = ref([]);
const getListCat = ref([]);
const theCat = ref([]);

/*
 * RELACION DE REFACCIONES
*/
const filterSpares = ref([])
const getListSpare = ref([]);
const theSpare = ref([]);

/*
 * RELACION DE OTROS PRODUCTOS
*/
const filterProducts = ref([])
const getListProduct = ref([]);
const theProduct = ref([]);

/*
 * RELACION DE TAGS
*/
const filterTags = ref([])
const getListTag = ref([]);
const theTag = ref([]);

/*METODOS*/
const onSaveRelCat = async () => {
    let resp = '';
    const data = {
        product_id: props.product_id,
        categories: getListCat.value.map((cat) => cat.value),
    };
    resp = await vsproductcategoryService.updateOne(props.product_id, data, false);
    getAlert(resp.process);
}

const onSaveRelSpare = async () => {
    let resp = '';
    const data = {
        product_id: props.product_id,
        spares: getListSpare.value.map((spare) => spare.value),
    };
    resp = await vsproductspareService.saveOne(data);
    getAlert(resp.process);
}

const onSaveRelProduct = async () => {
    let resp = '';
    const data = {
        product_id: props.product_id,
        products: getListProduct.value.map((product) => product.value),
    };
    resp = await vsrelationproductService.updateOne(props.product_id, data, false);
    getAlert(resp.process);
}

const onSaveRelTag = async () => {
    let resp = '';
    const data = {
        product_id: props.product_id,
        tags: getListTag.value.map((tag) => tag.value),
    };
    resp = await vsproducttagService.updateOne(props.product_id, data, false);
    getAlert(resp.process);
}

onMounted(async () => {

    vModel.value = await productsService.getProduct(props.product_id);

    // Se obtienen las categorías
    theCat.value = await categoriesService.getMany();
    filterCats.value = theCat.value.map(cat => ({
        value: cat.id,
        label: cat.name
    }));

    getListCat.value = vModel.value.vs_product_categories.map(cat => ({
        value: cat.id,
        label: cat.name
    }));

    // Se obtienen los productos tipo Spare
    theSpare.value = await sparesService.getProductsSpares(props.product_id);
    filterSpares.value = theSpare.value.map(spare => ({
        value: spare.id,
        label: spare.name
    }));

    getListSpare.value = vModel.value.spares.map(spare => ({
        value: spare.id,
        label: spare.name
    }));

    // Se obtienen los productos tipo Product
    theProduct.value = await productsService.getMany();
    filterProducts.value = theProduct.value.map(product => ({
        value: product.id,
        label: product.name
    }));

    getListProduct.value = vModel.value.vs_relation_products.map(vs_relation_products => {
        return {
            value: vs_relation_products.related_product.id,
            label: vs_relation_products.related_product.name
        };
    });

    // Se obtienen los Tags
    theTag.value = await tagsService.getMany();
    filterTags.value = theTag.value.map(tag => ({
        value: tag.id,
        label: tag.name
    }));

    getListTag.value = vModel.value.vs_product_tags.map(theTag => {
        return {
            value: theTag.tag.id,
            label: theTag.tag.name
        };
    });

});


</script>

<template>
    <div class="col-md-12">
        <div class="card mb-4">
            <div class="card-body">
                <div class="card-title mb-3"><i class="fa fa-tag"></i> Relaciones del Producto <small
                    class="text-danger">(por favor, no olvides guardar sección por sección)</small></div>
                <form>
                    <div class="row">
                        <div class="form-group col-md-12">
                            <fieldset>
                                <label class="ul-form__label" for="">Categorías del Producto</label>
                                <div class="form-group">
                                    <Multiselect
                                        v-model="getListCat"
                                        mode="tags"
                                        :close-on-select="false"
                                        :searchable="true"
                                        :object="true"
                                        :resolve-on-load="false"
                                        :options="filterCats"
                                    />
                                </div>
                                <div class="modal-footer my-4"><!--- Línea División-->
                                    <button class="btn btn-success ml-2" type="button" @click="onSaveRelCat()">
                                        <i class="fa fa-tag text-white mr-2"></i> Guardar
                                    </button>
                                </div>
                            </fieldset>
                        </div>
                        <div class="form-group col-md-12">
                            <fieldset>
                                <label class="ul-form__label" for="">Refacciones del Producto</label>
                                <div class="form-group">
                                    <Multiselect
                                        v-model="getListSpare"
                                        mode="tags"
                                        :close-on-select="false"
                                        :searchable="true"
                                        :object="true"
                                        :resolve-on-load="false"
                                        :options="filterSpares"
                                    />
                                </div>
                                <div class="modal-footer my-4"><!--- Línea División-->
                                    <button class="btn btn-success ml-2" type="button"
                                            @click="onSaveRelSpare()">
                                        <i class="fa fa-tag text-white mr-2"></i> Guardar
                                    </button>
                                </div>
                            </fieldset>
                        </div>
                        <div class="form-group col-md-12">
                            <fieldset>
                                <label class="ul-form__label" for="">Otros Productos Relacionados</label>
                                <div class="form-group">
                                    <Multiselect
                                        v-model="getListProduct"
                                        mode="tags"
                                        :close-on-select="false"
                                        :searchable="true"
                                        :object="true"
                                        :resolve-on-load="false"
                                        :options="filterProducts"
                                    />
                                </div>
                                <div class="modal-footer my-4"><!--- Línea División-->
                                    <button class="btn btn-success ml-2" type="button"
                                            @click="onSaveRelProduct()">
                                        <i class="fa fa-tag text-white mr-2"></i> Guardar
                                    </button>
                                </div>
                            </fieldset>
                        </div>
                        <div class="form-group col-md-12">
                            <fieldset>
                                <label class="ul-form__label" for="">Tags</label>
                                <div class="form-group">
                                    <Multiselect
                                        v-model="getListTag"
                                        mode="tags"
                                        :close-on-select="false"
                                        :searchable="true"
                                        :object="true"
                                        :resolve-on-load="false"
                                        :options="filterTags"
                                    />
                                </div>
                                <div class="modal-footer my-4"><!--- Línea División-->
                                    <button class="btn btn-success ml-2" type="button" @click="onSaveRelTag()">
                                        <i class="fa fa-tag text-white mr-2"></i> Guardar
                                    </button>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>
