<script setup>
import {onMounted, ref, watch} from 'vue'
import Multiselect from '@vueform/multiselect';
import {productsService} from "../../../../services/Products.service";
import {gralHook} from "../../../../Hooks/gralHook";
import {featuredProductsService} from "../../../../services/FeaturedProducts.service";

const {getAlert, goBack} = gralHook();
const filterProducts = ref([])
const getListProduct = ref([]);
const theProduct = ref([]);
//const vModelP = ref({});

const onSaveFeaturedProduct = async () => {
    let resp = '';
    const data = {
        products: getListProduct.value.map((product) => product.value),
    };
     resp = await featuredProductsService.saveOne( data);
     getAlert(resp.process);
}

onMounted(async () => {

    let Fproducts = await featuredProductsService.getMany();
    theProduct.value = await productsService.getMany();

    filterProducts.value = theProduct.value
        .filter(pdcto => pdcto.type === 1)
        .map(pdcto => ({
            value: pdcto.id,
            label: pdcto.name
        }));

    getListProduct.value = Fproducts.map((product) => ({
        value: product.product_id,
        label: product.product.name
    }));
    //console.log('filterProducts', filterProducts.value);
})
</script>

<template>
    <section>

        <div class="col-md-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="card-title mb-3"><i class="fa fa-tag"></i> Sección III - Productos Destacados</div>
                    <p>Te recomendamos poner máximo 7 siete para evitar que la página principal tarde en cargar. Los
                        productos que inactives dejarán de aparecer </p>
                    <form>
                        <div class="row">
                            <div class="form-group col-md-12">
                                <fieldset>
                                    <label class="ul-form__label" for="">Productos Destacados</label>
                                    <div class="form-group">
                                        <!--- Se muestra la lista de los nombres de los Productos tipo Product--->
                                        <Multiselect
                                            v-model="getListProduct"
                                            mode="tags"
                                            :close-on-select="false"
                                            :searchable="true"
                                            :object="true"
                                            :resolve-on-load="false"
                                            :options="filterProducts"
                                        />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div class="modal-footer my-4"><!--- Línea División-->
                            <button class="btn btn-success ml-2" type="button" @click="onSaveFeaturedProduct()">
                                <i class="fa fa-tag text-white mr-2"></i> Guardar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </section>
</template>

<style scoped>

</style>
